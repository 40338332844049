import {on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();


export function init () {

    onFind('.js-scroll-slider-teaser', function (baseElement) {
        scrollSlider.createScrollSlider(
            baseElement, {
                arrows: true,
                dots: true,
                arrowIconClass: 'icon icon-arrow-right-long'
            },{
                base: '.js-scroll-slider',
                area: '.js-scroll-slider__area',
                item: '.js-scroll-slider__item',
                dotsContainer: '.js-scroll-slider__dots'
            }
        );
    });

}