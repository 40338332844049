import './scripts/common';
import './scripts/common-nav';

import {onFind} from "@elements/init-modules-in-scope";

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();

import * as hoverImages from './scripts/hover-images';
hoverImages.init();

import Tab from 'bootstrap/js/dist/tab';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as parallax from './scripts/parallax';
parallax.initInScope();

import * as slideOnScroll from './scripts/slide-on-scroll';
slideOnScroll.initInScope();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init({
    arrows: false,
});

import * as teaserSlider from './scripts/teaser-slider';
teaserSlider.init();

import * as actionChanger from "@elements/action-changer";
actionChanger.init();

import * as openCartSidebar from "./scripts/open-cart-sidebar";
openCartSidebar.init();

import * as popperJS from "./scripts/libs/popper";
popperJS.init();

import * as initPopovers from "./scripts/init-popovers";
initPopovers.init();

import * as wishlist from './scripts/wishlist';
wishlist.init();

import * as ajaxModal from './scripts/ajax-modal';
ajaxModal.init();

import * as numberSpinner from '@elements/number-spinner';
numberSpinner.init();

import * as productSuccessModal from './scripts/product-success-modal';
productSuccessModal.init();

import * as ajaxForm from '@elements/ajax-form';
ajaxForm.init();

import * as countCartItems from './scripts/count-cart-items';
countCartItems.init();
